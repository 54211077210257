import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: "/",
    name: "homePage",
    component: () => import("@/views/homePage/index.vue"),
    meta: {
      title: "北京体育彩票",
    },
    redirect: "homePage",
  },
  {
    path: "/homePage",
    name: "homePage",
    component: () => import("@/views/homePage/rot.vue"),
    meta: {
      title: "首页",
    },
    redirect: "homePage/index",
    children: [
      {
        path: "index",
        component: () => import("@/views/homePage/index.vue"),
        name: "index",
        meta: {
          title: "竞猜资讯",
        }
      },
      {
        path: "leavingAMessage",
        component: () => import("@/views/homePage/leavingAMessage.vue"),
        name: "leavingAMessage",
        meta: {
          title: "留言反馈",
        }
      },
      {
        path: "eventChange",
        component: () => import("@/views/homePage/eventChange.vue"),
        name: "eventChange",
        meta: {
          title: "赛事情报速递",
        }
      },
      {
        path: "announcement",
        component: () => import("@/views/homePage/announcement.vue"),
        name: "announcement",
        meta: {
          title: "通知公告",
        }
      },
      {
        path: "hotNews",
        component: () => import("@/views/homePage/hotNews.vue"),
        name: "hotNews",
        meta: {
          title: "热点新闻",
        }
      },
      {
        path: "newsText",
        component: () => import("@/views/homePage/newsText.vue"),
        name: "newsText",
        meta: {
          title: "正文",
        }
      }]

  },
  {
    path: "/BonusCalculation",
    component: () => import("@/views/BonusCalculation/WinEvenLose.vue"),
    name: "BonusCalculation",
    meta: {
      title: "奖金计算胜平负",
    },
  },
  {
    path: "/BonusCalculation/WinLose",
    component: () => import("@/views/BonusCalculation/WinLose.vue"),
    name: "WinLose",
    meta: {
      title: "奖金计算胜负过关",
    },
  },
  {
    path: "/BonusCalculation/TotalGoals",
    component: () => import("@/views/BonusCalculation/TotalGoals.vue"),
    name: "TotalGoals",
    meta: {
      title: "奖金计算总进球数",
    },
  },
  {
    path: "/BonusCalculation/HalfFullCourtSPF",
    component: () => import("@/views/BonusCalculation/HalfFullCourtSPF.vue"),
    name: "HalfFullCourtSPF",
    meta: {
      title: "奖金计算半全场胜平负",
    },
  },
  {
    path: "/BonusCalculation/SingleDouble",
    component: () => import("@/views/BonusCalculation/SingleDouble.vue"),
    name: "SingleDouble",
    meta: {
      title: "奖金计算上下盘单双数",
    },
  },
  {
    path: "/BonusCalculation/SingleGameScore",
    component: () => import("@/views/BonusCalculation/SingleGameScore.vue"),
    name: "SingleGameScore",
    meta: {
      title: "奖金计算单场比分",
    },
  },
  {
    path: "/BonusCalculation/xbcbf",
    component: () => import("@/views/BonusCalculation/xbcbf.vue"),
    name: "BonusCalculationxbcbf",
    meta: {
      title: "奖金计算下半场比分",
    },
  },
  {
    path: "/immediate",
    component: () => import("@/views/immediate/WinEvenLose.vue"),
    name: "immediate",
    meta: {
      title: "投注分布胜平负",
    },
  },
  {
    path: "/immediate/WinLose",
    component: () => import("@/views/immediate/WinLose.vue"),
    name: "immediateWinLose",
    meta: {
      title: "投注分布胜负过关",
    },
  },
  {
    path: "/immediate/TotalGoals",
    component: () => import("@/views/immediate/TotalGoals.vue"),
    name: "immediateTotalGoals",
    meta: {
      title: "投注分布总进球数",
    },
  },
  {
    path: "/immediate/HalfFullCourtSPF",
    component: () => import("@/views/immediate/HalfFullCourtSPF.vue"),
    name: "immediateHalfFullCourtSPF",
    meta: {
      title: "投注分布半全场胜平负",
    },
  },
  {
    path: "/immediate/SingleDouble",
    component: () => import("@/views/immediate/SingleDouble.vue"),
    name: "immediateSingleDouble",
    meta: {
      title: "投注分布上下盘单双数",
    },
  },
  {
    path: "/immediate/SingleGameScore",
    component: () => import("@/views/immediate/SingleGameScore.vue"),
    name: "immediateSingleGameScore",
    meta: {
      title: "投注分布单场比分",
    },
  },
  {
    path: "/immediate/xbcbf",
    component: () => import("@/views/immediate/xbcbf.vue"),
    name: "immediateXbcbf",
    meta: {
      title: "投注分布下半场比分",
    },
  },
  {
    path: "/drawNotice",
    component: () => import("@/views/drawNotice.vue"),
    name: "drawNotice",
    meta: {
      title: "开奖公告",
    },
  },
  {
    path: "/encyclopedias",
    component: () => import("@/views/encyclopedias/index.vue"),
    name: "encyclopedias",
    meta: {
      title: "北单百科",
    }
  },
  // {
  //   path: "/SouthKorea",
  //   component: () => import("@/views/SouthKorea/index.vue"),
  //   name: "Ogindex",
  //   meta: {
  //     title: "韩国K1联赛",
  //   }
  // },
  {
    path: "/Ogindex",
    component: () => import("@/views/ogindex/index.vue"),
    name: "Ogindex",
    meta: {
      title: "欧冠联赛",
    }
  },
  {
    path: "/Iceland",
    component: () => import("@/views/Iceland/index.vue"),
    name: "Ogindex",
    meta: {
      title: "冰岛专题",
    }
  },
  {
    path: "/liveBroadcast",
    component: () => import("@/views/bifen/liveBroadcast.vue"),
    name: "LiveBroadcast",
    meta: {
      title: "比分直播",
    }
  },
  {
    path: "/matchData",
    component: () => import("@/views/bifen/matchData.vue"),
    name: "matchData",
    meta: {
      title: "对阵数据",
    }
  },
  {
    path: "/match",
    component: () => import("@/views/match/index.vue"),
    name: "Match",
    meta: {
      title: "赛事资料",
    }
  },
  {
    path: "/match/results",
    component: () => import("@/views/match/results.vue"),
    name: "Results",
    meta: {
      title: "赛程赛果",
    }
  },
  {
    path: "/winView",
    component: () => import("@/views/winView/index.vue"),
    name: "winView",
    meta: {
      title: "赢家视角",
    }
  },
  {
    path: "/winView/videoText",
    component: () => import("@/views/winView/videoText.vue"),
    name: "videoText",
    meta: {
      title: "赢家视角",
    }
  },
]

const router = new VueRouter({
  routes,
  mode: "hash",
})


export default router