<template>
  <div id="app">
    <!-- <div class="setPage" @click="goUrl"></div> -->
    <router-view></router-view>
    <div class="totopbox">
      <img
        @click="totop"
        v-show="isshow"
        class="totop"
        src="@/assets/imgs/totop.png"
        alt=""
      />
    </div>
  </div>
</template>

<script> 
export default {
  name: "App",
  components: {},
  data() {
    return {
      isshow: false,
    };
  },
  mounted() { 

    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 300) {
        this.isshow = true;
      } else {
        this.isshow = false;
      }
    });
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    totop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    goUrl () {
      window.open(this.$bgUrl+'worldCupWomen')
    }
  },
};
</script>
<style lang="scss">

.el-message,
.el-message--success {
  font-size: 0.14rem;
}
.dialog-fade-leave-active {
  transition-duration: 0s !important; //定义 退出时的 动画 过渡时间
  animation: none !important;
}
</style>  
<style>
@import url("./assets/css/quill.bubble.css");
@import url("./assets/css/quill.core.css");
@import url("./assets/css/quill.snow.css");
@import url("./assets/css/common.css");

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotateZ(36000000deg);
  }
}
#app {
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 11rem; 
  box-sizing:border-box; 
}
.setPage{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 12vw;
}
table {
  border-color: #ccc !important;
}
table td {
  height: 0.26rem;
}
.match-list::-webkit-scrollbar {
  width: 10px; /*对垂直流动条有效*/
  height: 10px; /*对水平流动条有效*/
}

/*定义滚动条的轨道颜色、内阴影及圆角*/
.match-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: rgba(80, 110, 81, 0.9);
  /* border-radius: 3px; */
}

/*定义滑块颜色、内阴影及圆角*/
.match-list::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #9fcb89;
}

/*定义两端按钮的样式*/
.match-list::-webkit-scrollbar-button {
  background: rgba(80, 110, 81, 0.9);
}

/*定义右下角汇合处的样式*/
.match-list::-webkit-scrollbar-corner {
  background: rgba(80, 110, 81, 0.9);
}
td {
  padding: 0 0.05rem;
  min-width: 0.5rem;
  border-color: #ccc;
}
</style>
<style lang="scss">
.ql-editor {
  line-height: 1.75;
  letter-spacing: 1.5px;
}
.center {
  position: relative;
  z-index: 22;
}
.totopbox {
  // height: 0.49rem;
  width: 14.4rem;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1.22rem;
  z-index: 0;

  .totop {
    position: absolute;
    cursor: pointer;
    right: 0.05rem;
    width: 0.3rem;
    height: 0.65rem;
  }
}
.el-select .el-input__inner {
  line-height: 0.25rem;
}
.el-message,
.el-message--success {
  font-size: 0.14rem;
}
.el-loading-spinner {
  /*这个是自己想设置的 gif 加载动图*/
  background-image: url("./assets/imgs/zuqiu.png");
  background-repeat: no-repeat;
  background-size: 0.6em 0.6em;
  height: 0.6em;
  width: 0.6em !important;
  background-position: center center;
  /*覆盖 element-ui  默认的 50%    因为此处设置了height:100%,所以不设置的话，会只显示一半，因为被top顶下去了*/
  top: calc(50% - 0.3rem);
  left: calc(50% - 0.3rem);
  transform-origin: center center;
  animation: rotate 250000s infinite linear;
}
.el-loading-spinner .circular {
  /*隐藏 之前  element-ui  默认的 loading 动画*/
  display: none;
}
.el-loading-spinner .el-loading-text {
  /*为了使得文字在loading图下面*/
  // margin: 85px 0px;
  display: none;
}
</style>
