import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'swiper/css/bundle'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import VideoPlayer from 'vue-video-player/src'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import axios from 'axios';
router.beforeEach((to, from, next) => {
  // if (to.query.homePageRotation) {
  // } else 
  if (to.name != 'Ogindex' && navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )) {
    axios.get("/config.json").then((res) => {
      window.location.href = res.data.pcUrl;
    });
  }
  if (to.path) {
    window._hmt.push(['_trackPageview', 'http://bjdc.dashengclass.com/#' + to.fullPath]);
  }
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next();
})

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VideoPlayer)
axios.get('/config.json').then(res => {
  Vue.prototype["phoneURL"] = res.data.pcUrl;
  Vue.prototype["baseURL"] = res.data.imgIp;
  Vue.prototype["baseURLs"] = res.data.baseUrl;
  Vue.prototype["$bgUrl"] = res.data.bgUrl;
  new Vue({
    router,
    render: h => h(App),
  }).$mount('#app')
})

